const message = {
  "button.home.sendFiles": "Enviar arquivos",
  "button.options.record": "Gravar agora",
  "button.options.upload": "Escolher arquivos",

  "title.notFound": "Ops, parece que essa página não existe.",
  "title.eventNotFound": "Erro ao acessar o evento",

  "subtitle.goBackTo": "Voltar para a",
  "subtitle.eventNotFound": "Evento não encontrado ou expirado.",

  "message.termsOfUse":
    "Ao utilizar nosso sistema você concorda com nossos <code>Termos de Uso</code>",
  "message.termsFirstParagraph":
    "Ao usar nosso sistema de envio de fotos e vídeos, você, como convidado do evento, concorda que coletamos e enviamos seus dados, incluindo  fotos, vídeos e informações pessoais, e disponibilize para o anfitrião do evento.",
  "message.termsSecondParagraph":
    "Nós não acessamos seus arquivos, a menos que seja solicitado pelo cliente para fornecer suporte técnico, limitando-se ao necessário.",
  "message.termsThirdParagraph":
    "A empresa não compartilha os dados com terceiros e mantém a confidencialidade das informações, exceto quando exigido por lei.",
  "message.options.record": "Grave agora sua mensagem especial!",
  "message.options.upload": "Compartilhe as melhores fotos e vídeos da festa!",
  "message.shareLink": "Compartilhe seus momentos favoritos da festa!",

  // recorder
  "button.recorder.understood": "Entendi!",
  "button.recorder.close": "Fechar",
  "button.recorder.share": "Compartilhar",
  "button.recorder.home": "Retornar à tela inicial",
  "button.recorder.back": "Voltar",

  "title.recorder.permissionsToRecordAudioVideo": "Permissão para Gravar",
  "title.recorder.success": "Sucesso!",
  "title.recorder.uploading": "Enviando",
  "title.recorder.alert": "Alerta!",
  "title.recorder.saving": "Salvando",
  "title.recorder.waiting": "Aguardando",
  "title.recorder.share": "Compartilhe!",
  "title.recorder.error": "Erro!",
  "title.recorder.permissionCameraOrMicrophoneDenied":
    "Permissão não concedida",
  "title.recorder.terms": "Termos de Uso",

  "subtitle.recorder.nextScreenClickAllow":
    "Na próxima tela clique em <code>Permitir</code>.",
  "subtitle.recorder.allowReinforcement":
    "É necessário que você clique em <code>Permitir</code> para gravar sua mensagem.",
  "subtitle.recorder.share": "Convide seus amigos para gravar também!",
  "subtitle.recorder.dontRefreshPage":
    "Por favor, não atualize ou feche esta página.",
  "subtitle.recorder.status.success": "Sua mensagem foi gravada com sucesso!",
  "subtitle.recorder.browserNotSupported":
    "Seu navegador não é suportado ou a permissão não foi concedida.",
  "subtitle.recorder.tryGrantPermission":
    "1. Atualize a página e permita o uso da câmera.",
  "subtitle.recorder.tryAnotherBrowser":
    "2. Tente usar outro navegador como {chrome} ou {safari}.",
  "subtitle.recorder.mustLongerThan":
    "A mensagem deve ter uma duração maior que <code>5 segundos</code>.",
  "subtitle.recorder.offline":
    "Você está offline. Por favor, verifique sua conexão.",
  "subtitle.recorder.startRecord": "Clique para começar a gravar.",
  "subtitle.recorder.recording": "Gravando...",
  "subtitle.recorder.uploadError": "Erro ao fazer upload do vídeo.",
  "subtitle.recorder.tryAgain": "Verifique sua conexão e tente novamente.",
  "subtitle.recorder.permissionCameraOrMicrophoneDenied":
    "Não foi permitido o uso da câmera/microfone.",
  "subtitle.recorder.trySafariBrowser":
    "1. Use o navegador <code>SAFARI</code>.",
  "subtitle.recorder.changeSiteSettingsUsingSafari":
    '2. Clique no botão <code>"aA"</code> na barra da URL e em <code>"Ajustes do site"</code>',
  "subtitle.recorder.grantCameraAndMicrophonePermissions":
    "3. <code>Permita o uso</code> da câmera e microfone",
  "subtitle.recorder.refreshPage": "4. Atualize a página",
  "subtitle.recorder.tryChromeBrowser":
    "1. Use o navegador <code>CHROME</code>",
  "subtitle.recorder.changeSiteSettingsUsingChrome":
    '2. Acesse as configurações do site e clique em <code>"Permissões"</code>',

  "message.recorder.almostDone":
    "Quase terminando! Apenas mais alguns segundos.",
  "message.recorder.sendingMessage":
    "Agora estamos enviando sua mensagem. Pode demorar um pouco porque está cheia de coisas legais.",

  // uploader
  "button.uploader.send_photos": "Enviar fotos",
  "button.uploader.send_video": "Enviar vídeo",
  "button.uploader.close": "Fechar",
  "button.uploader.share": "Compartilhar",
  "button.uploader.back": "Voltar",
  "button.uploader.send": "Enviar",
  "button.uploader.continue": "Continuar",
  "button.uploader.ok": "OK!",

  "title.uploader.success": "Sucesso!",
  "title.uploader.uploading": "Enviando",
  "title.uploader.alert": "Alerta!",
  "title.uploader.saving": "Salvando",
  "title.uploader.waiting": "Aguardando",
  "title.uploader.share": "Compartilhe!",
  "title.uploader.error": "Erro!",
  "title.uploader.upload": "Upload",
  "title.uploader.shareMoments": "Compartilhe momentos",
  "title.uploader.uploadFailure": "Falha no envio",
  "title.uploader.completed": "Concluído",
  "title.uploader.terms": "Termos de Uso",

  "subtitle.uploader.filesWithError":
    "Arquivos que <code>não serão enviados</code:",
  "subtitle.uploader.uploadHistory": "Envios concluídos",
  "subtitle.uploader.filesReadyToUpload": "Arquivos prontos para o envio:",

  "message.uploader.uploadMaxFilesLimit":
    "Envie até <code>50 fotos ou 1 vídeo</code> por vez.",
  "message.uploader.uploadLimit": "Limite máximo:",
  "message.uploader.photoSizeLimit": "<code>20MB</code> por foto",
  "message.uploader.videoSizeLimit": "<code>500MB</code> por vídeo",
  "message.uploader.fileSizeError": "Arquivo excede tamanho permitido.",
  "message.uploader.uploading": "Por favor, não atualize ou feche esta página.",
  "message.uploader.uploadError": "Falha ao enviar.",
  "message.uploader.maxFilesLimitError":
    "Selecione no <code>máximo 50 fotos</code>.",
  "message.uploader.uploader.notFoundFilesToUpload":
    "<code>Nenhum arquivo pode ser enviado</code>.",
  "message.uploader.checkFileSizeAndTryAgain":
    "Por favor, verifique o <code> tamanho do arquivo</code> e tente novamente.",
  "message.uploader.uploadComplete": "Upload concluído",
  "message.uploader.allFilesUploaded": "Todos os arquivos foram enviados",
  "message.uploader.nFilesUploaded": "{n} arquivos enviados",
  "message.uploader.nFilesNotUploaded": "{n} arquivo(s) não foram enviado(s)",
  "message.uploader.noFilesUploaded": "Nenhum arquivo foi enviado",
  "message.uploader.checkYourConnectionAndTryAgain":
    "Verifique sua conexão e tente novamente.",
  "message.uploader.uploadVerySlow":
    "Caso o envio esteja lento, selecione poucas fotos por vez",
  "message.uploader.uploadTip":
    "Para manter a qualidade do arquivo, faça o upload de arquivos da galeria do seu aparelho.",
};

export default message;

import QRCode from "components/QRCode";
import { useLogEvent } from "hooks/useLogEvent";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useEventStore } from "store/useEventStore";

export default function Share() {
  const { theme, event } = useEventStore();
  const { logShareButton } = useLogEvent();

  const qrCodeLink = useMemo(() => {
    logShareButton();
    return `https://evento.capsuladotempo.com/?code=${event?.code}`;
  }, [event?.code, logShareButton]);

  const intl = useIntl();

  const onShare = useCallback(() => {
    const text = intl.formatMessage({
      id: "message.shareLink",
      defaultMessage: "",
    });
    if (navigator.share) {
      navigator
        .share({
          url: qrCodeLink,
          text: text,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      console.log("no-navigator");
    }
  }, [qrCodeLink, intl]);

  const onBackHome = useCallback(() => {
    window.location.href = `/?code=${event?.code}`;
  }, [event?.code]);

  return (
    <div className="px-10 py-16 flex flex-col items-center">
      <h1 className="mb-9 text-theme-black">
        <FormattedMessage
          id="title.recorder.share"
          defaultMessage="Compartilhe!"
        />
      </h1>
      <div
        className={`flex flex-col items-center py-10 px-8 mb-14 card ${theme.card}`}
      >
        <p className="mb-8 text-theme-black">
          <FormattedMessage
            id="subtitle.recorder.share"
            defaultMessage="Convide seus amigos para gravar também!"
          />
        </p>
        <div className="mb-8 w-1/2">
          <QRCode code={event?.code} />
        </div>

        <button
          className={`mb-4 py-3 px-10 ${theme.button}`}
          type="button"
          onClick={onShare}
        >
          <FormattedMessage
            id="button.recorder.share"
            defaultMessage="Compartilhar"
          />
        </button>

        <button
          className={`py-3 px-10 ${theme.button}`}
          type="button"
          onClick={onBackHome}
        >
          <FormattedMessage
            id="button.recorder.home"
            defaultMessage="Retornar à tela inicial"
          />
        </button>
      </div>
    </div>
  );
}

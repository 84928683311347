import firebaseConfig from "libs/firebase/FirebaseConfig";
import { logEvent } from "firebase/analytics";
import { useEventStore } from "store/useEventStore";
import { useCallback, useMemo } from "react";
import { clarity } from "react-microsoft-clarity";

export const useLogEvent = () => {
  const { event } = useEventStore();
  const { analytics } = firebaseConfig;

  const uid = getUniqueID();

  const baseParams = useMemo(() => {
    return {
      id: uid,
      eventCode: event?.code,
    };
  }, [uid, event]);

  const sendLog = useCallback(
    (event: string, params: any) => {
      if (process.env.NODE_ENV === "development") {
        console.log(`log ${event}`, params);
      } else {
        logEvent(analytics, event, params);

        if (clarity.hasStarted()) {
          clarity.setTag(event, params.eventCode);
        }
      }
    },
    [analytics]
  );

  // log login
  const logLogin = useCallback(async () => {
    const contextData = await getContextData();
    const params = {
      ...baseParams,
      ...contextData,
      action: "login",
      dateTime: new Date().toISOString(),
    };
    sendLog("login", params);
  }, [baseParams, sendLog]);

  // log home record button
  const logHome = useCallback(() => {
    const params = {
      ...baseParams,
      action: "home",
      dateTime: new Date().toISOString(),
    };
    sendLog("home_access", params);
  }, [sendLog, baseParams]);

  // log guide button
  const logGuide = useCallback(() => {
    const params = {
      ...baseParams,
      action: "guide_record_button",
      dateTime: new Date().toISOString(),
    };
    sendLog("guide_record_button", params);
  }, [sendLog, baseParams]);

  const logUploadFileType = useCallback(
    (fileType: string) => {
      sendLog(fileType, baseParams);
    },
    [sendLog, baseParams]
  );

  // start upload file
  const logStartUploadFile = useCallback(
    (extraParams: { [key: string]: unknown }) => {
      const params = {
        ...baseParams,
        ...extraParams,
        action: "start_upload_file",
        dateTime: new Date().toISOString(),
      };
      sendLog("start_upload_file", params);
    },
    [sendLog, baseParams]
  );

  // stop upload file
  const logFinishUploadFile = useCallback(
    (extraParams: { [key: string]: unknown }) => {
      const params = {
        ...baseParams,
        ...extraParams,
        action: "finish_upload_file",
        dateTime: new Date().toISOString(),
      };
      sendLog("finish_upload_file", params);
    },
    [sendLog, baseParams]
  );

  // stop recording during debounce
  const logStopRecordingDuringDebounce = useCallback(() => {
    const params = {
      ...baseParams,
      action: "stop_recording_during_debounce",
      dateTime: new Date().toISOString(),
    };
    sendLog("stop_recording_during_debounce", params);
  }, [sendLog, baseParams]);

  // view success page
  const logViewSuccessPage = useCallback(() => {
    const params = {
      ...baseParams,
      action: "view_success_page",
      dateTime: new Date().toISOString(),
    };
    sendLog("view_success_page", params);
  }, [sendLog, baseParams]);

  // share button
  const logShareButton = useCallback(() => {
    const params = {
      ...baseParams,
      action: "share_button",
      dateTime: new Date().toISOString(),
    };
    sendLog("share_button", params);
  }, [sendLog, baseParams]);

  // upload error
  const logUploadFileError = useCallback(
    (extraParams: { [key: string]: unknown }) => {
      const params = {
        ...baseParams,
        ...extraParams,
        action: "upload_file_error",
        dateTime: new Date().toISOString(),
      };
      sendLog("upload_error", params);
    },
    [sendLog, baseParams]
  );

  const logChangeLanguage = useCallback(() => {
    sendLog("change_language", baseParams);
  }, [sendLog, baseParams]);

  // start recording
  const logStartRecording = useCallback(() => {
    const params = {
      ...baseParams,
      action: "start_recording",
      dateTime: new Date().toISOString(),
    };
    sendLog("start_recording", params);
  }, [sendLog, baseParams]);

  // stop recording
  const logStopRecording = useCallback(() => {
    const params = {
      ...baseParams,
      action: "stop_recording",
      dateTime: new Date().toISOString(),
    };
    sendLog("stop_recording", params);
  }, [sendLog, baseParams]);

  const logPageState = useCallback(
    (state: string) => {
      sendLog(state, baseParams);
    },
    [sendLog, baseParams]
  );

  // browser not supported error
  const logBrowserNotSupported = useCallback(() => {
    const params = {
      ...baseParams,
      action: "browser_not_supported",
      dateTime: new Date().toISOString(),
    };
    sendLog("browser_not_supported", params);
  }, [sendLog, baseParams]);

  // audio/video permissions denied
  const logCameraMicrophonePermissionsDenied = useCallback(() => {
    sendLog("camera_microphone_permissions_denied", baseParams);
  }, [sendLog, baseParams]);

  return {
    logLogin,
    logHome,
    logGuide,
    logViewSuccessPage,
    logShareButton,
    logStartUploadFile,
    logFinishUploadFile,
    logStopRecordingDuringDebounce,
    logUploadFileError,
    logChangeLanguage,
    logUploadFileType,
    logPageState,
    logStartRecording,
    logStopRecording,
    logBrowserNotSupported,
    logCameraMicrophonePermissionsDenied,
  };
};

export const getBrowser = () => {
  const browsers: { [key: string]: RegExp } = {
    Chrome: /Chrome/,
    Firefox: /Firefox/,
    Safari: /Safari/,
    Edge: /Edg/,
    IE: /MSIE|Trident/,
    Opera: /Opera|OPR/,
  };
  const { userAgent } = window.navigator;
  const isMobile = /Mobile/.test(userAgent);
  const isTablet = /Tablet/.test(userAgent);
  const isAndroid = /Android/.test(userAgent);
  const isiOS = /iPhone|iPad|iPod/.test(userAgent);
  const isWindows = /Windows/.test(userAgent);
  const isMac = /Macintosh/.test(userAgent);
  const isLinux = /Linux/.test(userAgent);
  const browser = Object.keys(browsers).find((browser) =>
    browsers[browser].test(userAgent)
  );
  const device = isMobile ? "mobile" : isTablet ? "tablet" : "desktop";
  const os = isAndroid
    ? "android"
    : isiOS
    ? "ios"
    : isWindows
    ? "windows"
    : isMac
    ? "mac"
    : isLinux
    ? "linux"
    : "other";
  return {
    browser,
    device,
    os,
  };
};

const getUniqueID = () => {
  const uid = localStorage.getItem("ct_uid");
  if (uid) {
    return uid;
  } else {
    const newUid = Math.random().toString(36).substring(2, 13);
    localStorage.setItem("ct_uid", newUid);
    return newUid;
  }
};

const getLocation = async (): Promise<
  | {
      country_code: string;
      city: string;
      region: string;
    }
  | undefined
> => {
  try {
    const response = await fetch("https://get.geojs.io/v1/ip/geo.json");
    const data = await response.json();
    const { country_code, city, region } = data;
    return { country_code, city, region };
  } catch (error) {
    console.error("error getting user location", error);
  }
};

const getIp = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("error getting user location", error);
  }
};

export const getContextData = async (): Promise<
  | {
      project: string;
      country: string;
      device: string;
      browser: string | undefined;
      os: string;
      ip: any;
      city: string;
      state: string;
    }
  | undefined
> => {
  try {
    const location = await getLocation();
    if (location) {
      const { country_code, city, region } = location;
      const { browser, device, os } = getBrowser();
      const ip = await getIp();
      return {
        project: `cdt-uploader-america.web.app`,
        country: country_code,
        device,
        browser,
        os,
        ip,
        city,
        state: region,
      };
    }
  } catch (error) {
    console.error("error getting user location", error);
  }
};

import { ActionFunctionArgs, redirect } from "react-router-dom";
import { TEvent } from "services/types";
import CdtAppPublicApi from "cdt-app-public-api/dist";
import { addDays, parse } from "date-fns";

const EVENT_ACCESS_DAYS_LIMIT = 7;

export const eventLoader = async ({ request }: ActionFunctionArgs) => {
  const url = new URL(request.url);
  const code = url.searchParams.get("code") || url.searchParams.get("event");
  const cdtAppClient = new CdtAppPublicApi();

  if (!code) {
    return null;
  }

  const event: TEvent | undefined = (await cdtAppClient.getEvent(
    code
  )) as TEvent;

  if (!event) {
    return redirect("/event-not-found");
  }

  const eventDate = parse(event.date, "yyyy-MM-dd", new Date());
  const accessDateLimit = addDays(eventDate, EVENT_ACCESS_DAYS_LIMIT);
  const today = new Date();

  if (accessDateLimit < today) {
    return redirect("/event-not-found");
  }

  let bannerImage = null;

  if (event.bannerUrl) {
    await new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.src = event.bannerUrl as string;
      img.onload = () => {
        bannerImage = event.bannerUrl;
        resolve();
      };
      img.onerror = reject;
    });
  }

  if (bannerImage) {
    event.bannerImage = bannerImage;
  }

  return event;
};

import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useEventStore } from "store/useEventStore";

type Props = {
  progress: number;
};

export default function UploadProgress({ progress }: Props) {
  const { theme } = useEventStore();
  const formattedProgress = progress > 98 ? 98 : progress;

  const statusName = useMemo(() => {
    if (progress < 96) {
      return (
        <FormattedMessage
          id="title.recorder.uploading"
          defaultMessage="Enviando"
        />
      );
    } else {
      return (
        <FormattedMessage
          id="title.recorder.saving"
          defaultMessage="Salvando"
        />
      );
    }
  }, [progress]);

  return (
    <div className="px-10 py-16 flex flex-col items-center">
      <h1 className="mb-9 text-theme-black">{statusName}</h1>
      <div
        className={`flex flex-col items-center py-10 px-8 card ${theme.card}`}
      >
        <p className="text-theme-black">
          <FormattedMessage
            id="subtitle.recorder.dontRefreshPage"
            defaultMessage="Por favor, não atualize ou feche esta página."
          />
        </p>
        <div className="my-8 relative h-4 w-full bg-gray-300 rounded-full overflow-hidden">
          <div
            style={{
              width: `${formattedProgress}%`,
              backgroundColor: theme?.progressBar,
            }}
            className="absolute left-0 h-full"
          ></div>
        </div>
        <div className="flex flex-col justify-center items-center h-32 w-full">
          <ul className="w-full h-full list-none relative flex justify-center items-start text-center text-gray-400">
            {progress === 100 ? (
              <li className="absolute flex flex-col justify-center items-center animate-pulse gap-4">
                <p className="font-century-gothic-bold">
                  <FormattedMessage
                    id="message.recorder.almostDone"
                    defaultMessage="Quase terminando! Apenas mais alguns segundos."
                  />
                </p>
                <span className="text-2xl">😮‍💨</span>
              </li>
            ) : (
              <li className="absolute flex flex-col justify-center items-center animate-pulse gap-4">
                <p className="font-century-gothic-bold">
                  <FormattedMessage
                    id="message.recorder.sendingMessage"
                    defaultMessage="Agora estamos enviando sua mensagem. Pode demorar um pouco porque está cheia de coisas legais."
                  />
                </p>
                <span className="text-2xl">📩</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

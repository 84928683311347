import bannerPlaceholder from "assets/images/evento-banner.jpg";
import WeddingRose from "templates/WeddingRose";
import WeddingWhite from "templates/WeddingWhite";
import Birthday from "templates/Birthday";
import { useEventStore } from "store/useEventStore";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useLogEvent } from "hooks/useLogEvent";
import { useIntl } from "react-intl";
import { shareLink } from "utils/utils";

export default function Home() {
  const { event } = useEventStore();
  const { logHome, logShareButton } = useLogEvent();

  const navigate = useNavigate();

  const qrCodeLink = useMemo(() => {
    return `https://evento.capsuladotempo.com/?code=${event?.code}`;
  }, [event?.code]);

  const intl = useIntl();

  const onShare = useCallback(() => {
    const text = intl.formatMessage({
      id: `message.shareLink`,
      defaultMessage: "Compartilhe seus momentos favoritos da festa!",
    });

    shareLink(qrCodeLink, text, logShareButton);
  }, [qrCodeLink, intl, logShareButton]);

  const onCapsulaLandingPageClick = useCallback(() => {
    const capsulaLadingPage = "https://videorecados.capsuladotempo.com";

    window.open(capsulaLadingPage, "_blank");
  }, []);

  const convertFontDefault = useCallback((font: string) => {
    if (font === "default") {
      return null;
    }
    return font;
  }, []);

  const actionButtonLabel = useMemo(() => {
    return intl.formatMessage({
      id: `button.home.sendFiles`,
      defaultMessage: "Enviar arquivos",
    });
  }, [intl]);

  const onClick = () => {
    logHome();

    if (event?.features.length === 1 && event.features.includes("uploader")) {
      return navigate("/upload");
    }

    if (event?.features.length === 1 && event.features.includes("recorder")) {
      return navigate("/record/guide");
    }

    navigate("/options");
  };

  switch (event?.customization?.template?.name) {
    case "wedding-rose":
      const weddingRoseValues = {
        bannerUrl: event.bannerImage || bannerPlaceholder,
        titleFontFamily:
          convertFontDefault(event.customization.template.titleFontFamily) ||
          "font-high-spirited",
        titleFontSize: event.customization.template.titleFontSize || "53px",
        titleOneText: event.customization.template.titleOneText as string,
        subtitleText: event.customization.template.subtitleText as string,
        onShare: onShare,
        onCapsulaLandingPageClick,
        actionButtonLabel,
        onClick: onClick,
      };

      return <WeddingRose {...weddingRoseValues} />;
    case "wedding-white":
      const weddingWhiteValues = {
        bannerUrl: event.bannerImage || bannerPlaceholder,
        titleFontFamily:
          convertFontDefault(event.customization.template.titleFontFamily) ||
          "font-playfair-display",
        titleFontSize: event.customization.template.titleFontSize || "30px",
        titleOneText: event.customization.template.titleOneText as string,
        titleTwoText: event.customization.template.titleTwoText as string,
        subtitleText: event.customization.template.subtitleText as string,
        onShare: onShare,
        onCapsulaLandingPageClick,
        actionButtonLabel,
        onClick: onClick,
      };

      return <WeddingWhite {...weddingWhiteValues} />;
    case "birthday-pink":
      const birthdayPinkValues = {
        bannerUrl: event.bannerImage || bannerPlaceholder,
        titleFontFamily:
          convertFontDefault(event.customization.template.titleFontFamily) ||
          "font-style-script",
        titleFontSize: event.customization.template.titleFontSize || "70px",
        titleOneText: event.customization.template.titleOneText as string,
        subtitleText: event.customization.template.subtitleText as string,
        titleOneShadowClass: "before:text-template-pink",
        templateColor: "bg-template-pink",
        wrapperClass: "bg-template-bg-pink",
        onShare: onShare,
        onCapsulaLandingPageClick,
        actionButtonLabel,
        onClick: onClick,
      };

      return <Birthday {...birthdayPinkValues} />;
    case "birthday-blue":
      const birthdayBlueValues = {
        bannerUrl: event.bannerImage || bannerPlaceholder,
        titleFontFamily:
          convertFontDefault(event.customization.template.titleFontFamily) ||
          "font-style-script",
        titleFontSize: event.customization.template.titleFontSize || "70px",
        titleOneText: event.customization.template.titleOneText as string,
        subtitleText: event.customization.template.subtitleText as string,
        titleOneShadowClass: "before:text-template-blue",
        templateColor: "bg-template-blue",
        wrapperClass: "bg-template-bg-blue",
        onShare: onShare,
        onCapsulaLandingPageClick,
        actionButtonLabel,
        onClick: onClick,
      };

      return <Birthday {...birthdayBlueValues} />;
    case "birthday-yellow":
      const birthdayYellowValues = {
        bannerUrl: event.bannerImage || bannerPlaceholder,
        titleFontFamily:
          convertFontDefault(event.customization.template.titleFontFamily) ||
          "font-style-script",
        titleFontSize: event.customization.template.titleFontSize || "70px",
        titleOneText: event.customization.template.titleOneText as string,
        subtitleText: event.customization.template.subtitleText as string,
        titleOneShadowClass: "before:text-template-yellow",
        templateColor: "bg-template-yellow",
        wrapperClass: "bg-template-bg-yellow",
        onShare: onShare,
        onCapsulaLandingPageClick,
        actionButtonLabel,
        onClick: onClick,
      };

      return <Birthday {...birthdayYellowValues} />;
    case "birthday-green":
      const birthdayGreenValues = {
        bannerUrl: event.bannerImage || bannerPlaceholder,
        titleFontFamily:
          convertFontDefault(event.customization.template.titleFontFamily) ||
          "font-style-script",
        titleFontSize: event.customization.template.titleFontSize || "70px",
        titleOneText: event.customization.template.titleOneText as string,
        subtitleText: event.customization.template.subtitleText as string,
        titleOneShadowClass: "before:text-template-green",
        templateColor: "bg-template-green",
        wrapperClass: "bg-template-bg-green",
        onShare: onShare,
        onCapsulaLandingPageClick,
        actionButtonLabel,
        onClick: onClick,
      };

      return <Birthday {...birthdayGreenValues} />;
    case "birthday-purple":
      const birthdayPurpleValues = {
        bannerUrl: event.bannerImage || bannerPlaceholder,
        titleFontFamily:
          convertFontDefault(event.customization.template.titleFontFamily) ||
          "font-style-script",
        titleFontSize: event.customization.template.titleFontSize || "70px",
        titleOneText: event.customization.template.titleOneText as string,
        subtitleText: event.customization.template.subtitleText as string,
        titleOneShadowClass: "before:text-template-purple",
        templateColor: "bg-template-purple",
        wrapperClass: "bg-template-bg-purple",
        onShare: onShare,
        onCapsulaLandingPageClick,
        actionButtonLabel,
        onClick: onClick,
      };

      return <Birthday {...birthdayPurpleValues} />;
    default:
      const defaultValues = {
        bannerUrl: event?.bannerImage || bannerPlaceholder,
        titleFontFamily: event?.customization?.template
          ?.titleFontFamily as string,
        titleFontSize: event?.customization?.template?.titleFontSize || "53px",
        titleOneText: event?.customization?.template?.titleOneText as string,
        subtitleText: event?.customization?.template?.subtitleText as string,
        onShare: onShare,
        onCapsulaLandingPageClick,
        actionButtonLabel,
        onClick: onClick,
      };
      return <WeddingRose {...defaultValues} />;
  }
}

const message = {
  "button.home.sendFiles": "Send files",
  "button.options.record": "Record now",
  "button.options.upload": "Choose files",

  "title.notFound": "Oops, it looks like this page does not exist.",
  "title.eventNotFound": "Error accessing the event",

  "subtitle.goBackTo": "Go back to",
  "subtitle.eventNotFound": "Event not found or expired.",

  "message.termsOfUse":
    "By using our system you agree to our <code>Terms of Use</code>",
  "message.termsFirstParagraph":
    "By using our photo and video submission system, you, as an event guest, agree that we collect and submit your data, including photos, videos and personal information, and make it available to the event host.",
  "message.termsSecondParagraph":
    "We do not access your files unless requested by the client to provide technical support, limited to what is necessary.",
  "message.termsThirdParagraph":
    "The company does not share data with third parties and maintains the confidentiality of information, except when required by law.",
  "message.options.record": "Record your special message now!",
  "message.options.upload": "Share the best photos and videos from the party!",
  "message.shareLink": "Share your favorite moments from the party!",

  // recorder
  "button.recorder.home": "Home",
  "button.recorder.understood": "Understood!",
  "button.recorder.close": "Close",
  "button.recorder.share": "Share",
  "button.recorder.back": "Back",

  "title.recorder.permissionsToRecordAudioVideo": "Permission to Record",
  "title.recorder.success": "Success!",
  "title.recorder.uploading": "Uploading",
  "title.recorder.alert": "Alert!",
  "title.recorder.saving": "Saving",
  "title.recorder.waiting": "Waiting",
  "title.recorder.share": "Share!",
  "title.recorder.error": "Error!",
  "title.recorder.permissionCameraOrMicrophoneDenied": "Permission not granted",
  "title.recorder.terms": "Terms of Use",

  "subtitle.recorder.nextScreenClickAllow":
    "On the next screen click on <code>Allow</code>.",
  "subtitle.recorder.allowReinforcement":
    "It is necessary for you to click on <code>Allow</code> to record your message.",
  "subtitle.recorder.share": "Invite your friends to record too!",
  "subtitle.recorder.dontRefreshPage":
    "Please, do not refresh or close this page.",
  "subtitle.recorder.status.success":
    "Your message has been recorded successfully!",
  "subtitle.recorder.browserNotSupported":
    "Your browser is not supported or permission was not granted.",
  "subtitle.recorder.tryGrantPermission":
    "1. Refresh the page and allow the use of the camera.",
  "subtitle.recorder.tryAnotherBrowser":
    "2. Try using another browser like {chrome} or {safari}.",
  "subtitle.recorder.mustLongerThan":
    "The message must be longer than <code>5 seconds</code>.",
  "subtitle.recorder.offline": "You are offline. Please check your connection.",
  "subtitle.recorder.startRecord": "Click to start recording.",
  "subtitle.recorder.recording": "Recording...",
  "subtitle.recorder.uploadError": "Error uploading video.",
  "subtitle.recorder.tryAgain": "Check your connection and try again.",
  "subtitle.recorder.permissionCameraOrMicrophoneDenied":
    "Camera/microphone permission not granted.",
  "subtitle.recorder.trySafariBrowser":
    "1. Use the <code>SAFARI</code> browser",
  "subtitle.recorder.changeSiteSettingsUsingSafari":
    '2. Click on the <code>"aA"</code> button in the URL bar and on <code>"Site Settings"</code>',
  "subtitle.recorder.grantCameraAndMicrophonePermissions":
    "3. <code>Allow</code> the use of the camera and microphone",
  "subtitle.recorder.refreshPage": "4. Refresh the page",
  "subtitle.recorder.tryChromeBrowser":
    "1. Use the <code>CHROME</code> browser",
  "subtitle.recorder.changeSiteSettingsUsingChrome":
    '2. Access site settings and click on <code>"Permissions"</code>',

  "message.recorder.almostDone": "Almost done! Just a few more seconds.",
  "message.recorder.sendingMessage":
    "We are now sending your message. It may take a while because it's full of cool stuff.",

  // uploader
  "button.uploader.send_photos": "Send photos",
  "button.uploader.send_video": "Send video",
  "button.uploader.close": "Close",
  "button.uploader.share": "Share",
  "button.uploader.back": "Back",
  "button.uploader.send": "Send",
  "button.uploader.continue": "Continue",
  "button.uploader.ok": "OK!",

  "title.uploader.success": "Success!",
  "title.uploader.uploading": "Uploading",
  "title.uploader.alert": "Alert!",
  "title.uploader.saving": "Saving",
  "title.uploader.waiting": "Waiting",
  "title.uploader.share": "Share!",
  "title.uploader.error": "Error!",
  "title.uploader.upload": "Upload",
  "title.uploader.shareMoments": "Share the moment",
  "title.uploader.uploadFailure": "Upload failure",
  "title.uploader.completed": "Completed",
  "title.uploader.terms": "Terms of Use",

  "subtitle.uploader.filesWithError":
    "Files that <code>will not be sent</code>:",
  "subtitle.uploader.uploadHistory": "Completed uploads",
  "subtitle.uploader.filesReadyToUpload": "Files ready for upload:",

  "message.uploader.uploadMaxFilesLimit":
    "Send up to <code>50 photos or 1 video</code> at a time.",
  "message.uploader.uploadLimit": "Maximum limit:",
  "message.uploader.photoSizeLimit": "<code>20MB</code> per photo",
  "message.uploader.videoSizeLimit": "<code>500MB</code> per video",
  "message.uploader.fileSizeError": "File exceeds allowed size.",
  "message.uploader.uploading": "Please, do not refresh or close this page.",
  "message.uploader.uploadError": "Failed to upload.",
  "message.uploader.maxFilesLimitError":
    "You can only upload <code>50 files at a time</code>.",
  "message.uploader.notFoundFilesToUpload": "<code>No files to upload.</code>",
  "message.uploader.checkFileSizeAndTryAgain":
    "Please, check the <code>file size </code> and try again.",
  "message.uploader.uploadComplete": "Upload completed",
  "message.uploader.allFilesUploaded": "All files uploaded",
  "message.uploader.nFilesUploaded": "{n} file(s) uploaded",
  "message.uploader.nFilesNotUploaded": "{n} file(s) not uploaded",
  "message.uploader.noFilesUploaded": "No files uploaded",
  "message.uploader.checkYourConnectionAndTryAgain":
    "Check your connection and try again.",
  "message.uploader.uploadVerySlow":
    "If the upload is slow, select a few photos at a time",
  "message.uploader.uploadTip":
    "To maintain file quality, upload files from your device's gallery.",
};

export default message;

import { LogoIcon } from "assets/svg";
import { useEventStore } from "store/useEventStore";

export default function LandingPageButton({
  onClick,
}: {
  onClick: React.MouseEventHandler;
}) {
  const { theme } = useEventStore();

  if (theme.name === "white") {
    return (
      <button
        onClick={onClick}
        type="button"
        className="shadow square-white bg-white text-theme-black p-3 h-10 w-10 flex justify-center items-center"
      >
        <LogoIcon width={24} height={24} outline="black" color="black" />
      </button>
    );
  }
  return (
    <button
      onClick={onClick}
      type="button"
      className="rounded-full bg-transparent border border-primary-rose text-primary-rose p-2.5 h-10 w-10 flex justify-center items-center"
    >
      <LogoIcon width={24} height={24} outline="#F76C6F" color="#F76C6F" />
    </button>
  );
}

import { useCallback } from "react";
import { useEventStore } from "store/useEventStore";

export default function ChangeLanguageButton({
  templateColor,
  template,
}: {
  templateColor?: string;
  template?: string;
}) {
  const { event, language, theme, setLanguage } = useEventStore();

  const label = language.split("-")[0].toUpperCase();

  const onChange = useCallback(() => {
    if (!event?.languages) return null;

    const index = event?.languages.indexOf(language);
    const nextIndex = index + 1;
    const nextLanguage = event.languages[nextIndex] || event.languages[0];

    setLanguage(nextLanguage as string);
  }, [language, setLanguage, event?.languages]);

  if (template === "birthday") {
    return (
      <button
        onClick={onChange}
        type="button"
        className={`${templateColor} shadow-md shadow-gray-300 font-century-gothic-bold text-sm rounded-full text-theme-black p-3 h-10 w-10 flex justify-center items-center`}
      >
        {label}
      </button>
    );
  }

  if (theme.name === "white") {
    return (
      <button
        onClick={onChange}
        type="button"
        className="shadow square-white bg-white text-theme-black p-3 h-10 w-10 flex justify-center items-center"
        data-testid="change-language"
      >
        {label}
      </button>
    );
  }

  return (
    <button
      type="button"
      className="rounded-full bg-transparent border border-primary-rose text-primary-rose p-3 h-10 w-10 flex justify-center items-center"
      onClick={onChange}
      data-testid="change-language"
    >
      {label}
    </button>
  );
}

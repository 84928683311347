import { LogoIcon, Share } from "assets/svg";
import { FormattedMessage } from "react-intl";
import { TTemplate } from "./Templates.types";
import { Link } from "react-router-dom";
import ChangeLanguageButton from "components/theme/ChangeLanguageButton";

export default function WeddingRose({
  bannerUrl,
  titleFontFamily,
  titleFontSize,
  titleOneText,
  subtitleText,
  onShare,
  onCapsulaLandingPageClick,
  actionButtonLabel,
  onClick,
}: TTemplate) {
  return (
    <div className="template-container flex flex-col items-center">
      <div className="relative wedding-rose">
        <img
          className="aspect-square object-cover w-full h-full"
          src={bannerUrl}
          alt="banner-home"
        />
      </div>
      <div className="px-5 mt-3 mb-4">
        <h6
          className={`${titleFontFamily} font-normal text-center font-shadow`}
          style={{
            fontSize: titleFontSize,
          }}
          translate="no"
        >
          {titleOneText}
        </h6>
      </div>
      <div className="flex flex-col items-center bg-white rounded-xl shadow-md shadow-gray-200 pt-8 p-7 gap-5 mx-10">
        {subtitleText && <p>{subtitleText}</p>}
        <button
          onClick={onClick}
          type="button"
          className="bg-primary-rose font-century-gothic-bold text-sm text-white py-3 px-10 flex whitespace-nowrap justify-center items-center my-0 mx-auto rounded-xl"
        >
          {actionButtonLabel}
        </button>
      </div>
      <div className="flex items-center justify-start gap-2 ml-4 mb-8 mt-7 place-self-start">
        <ChangeLanguageButton />

        <button
          onClick={onShare}
          type="button"
          className="rounded-full bg-transparent border border-primary-rose text-primary-rose p-2.5 h-10 w-10 flex justify-center items-center"
        >
          <Share width={20} height={20} outline="#F76C6F" color="#F76C6F" />
        </button>
        <button
          onClick={onCapsulaLandingPageClick}
          type="button"
          className="rounded-full bg-transparent border border-primary-rose text-primary-rose p-2.5 h-10 w-10 flex justify-center items-center"
        >
          <LogoIcon width={24} height={24} outline="#F76C6F" color="#F76C6F" />
        </button>
      </div>
      <div className="text-terms">
        <FormattedMessage
          id="message.termsOfUse"
          defaultMessage="Ao utilizar nosso sistema você concorda com nossos <code>Termos de Uso</code>"
          values={{
            code: (text) => (
              <Link to="/terms" className="underline text-terms">
                {text}
              </Link>
            ),
          }}
        />
      </div>
    </div>
  );
}
